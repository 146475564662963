import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '../Buttons';
import { deleteEvent } from '../../features/eventsSlice';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { useStorage } from '@bcx-tech/frontend-core';

type ConfirmEventDeleteProps = {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  eventToDelete: DanceEvent;
};

export const ConfirmEventDelete: FC<ConfirmEventDeleteProps> = ({
  show,
  handleClose,
  handleConfirm,
  eventToDelete,
}) => {
  const dispatch = useDispatch();
  const { deleteFile } = useStorage();
  const confirmAndDelete = async () => {
    if (eventToDelete) {
      const filesToDelete = [];
      if (eventToDelete.image) {
        filesToDelete.push(eventToDelete.image);
      }
      if (eventToDelete.attachments && eventToDelete.attachments.length) {
        filesToDelete.push(...eventToDelete.attachments);
      }
      await Promise.all(filesToDelete.map(deleteFile));
      dispatch(deleteEvent(eventToDelete));
    }
    handleConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the event <em>{eventToDelete.title}</em> and any files associated with it?
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton label='Cancel' handleClick={handleClose}></PrimaryButton>
        <PrimaryButton label='Yes' handleClick={confirmAndDelete}></PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

import React, { FC, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForgotAndResetPassword } from '@bcx-tech/frontend-core';

import { EmailField } from '@bcx-tech/weave';
import { PrimaryButton } from '../../components/Buttons';

import styles from './ForgotPassword.module.css';

export const ForgotPassword: FC = () => {
  const { handleSendResetPasswordEmail } = useForgotAndResetPassword();
  const [email, setEmail] = useState<string>();
  const [success, setSuccess] = useState<boolean>();

  const handleClick = async () => {
    if (email) {
      await handleSendResetPasswordEmail(email);
      setSuccess(true);
      setEmail(undefined);
    }
  };

  return (
    <>
      <h1 className={styles.title}>Forgotten your password?</h1>
      {success && (
        <Alert variant='success'>
          <h4>Reset link requested</h4>
          You should receive an email with a link to reset your password.{' '}
          <Link to={'/auth/login'}>Log in to the site</Link>.
        </Alert>
      )}
      <Form className={styles.form}>
        <EmailField onChangeText={(email) => setEmail(email)} />
        <PrimaryButton handleClick={handleClick} label='Reset password' />
      </Form>
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DanceLeague } from '@bcx-tech/tbc-types';
import { selectDanceLeagues, selectDanceLeagueLoadSuccess, fetchDanceLeagues } from '../../features/danceLeaguesSlice';
import { useLinkedEntity } from '../../hooks';

import { AutocompleteField, AutocompleteResult } from '@bcx-tech/weave';
import { RootState } from '../../store';

type DanceLeaguesEditProps = {
  danceLeagueIds?: string[];
  onChange: (danceLeagues: DanceLeague[]) => void;
};

export const DanceLeaguesEdit: FC<DanceLeaguesEditProps> = ({ danceLeagueIds, onChange }) => {
  const dispatch = useDispatch();
  const { mapToAutocomplete, mapToEntity, searchEntities } = useLinkedEntity<DanceLeague>('dance-leagues');

  const danceLeagueCollection = useSelector((state: RootState) => selectDanceLeagues(state, danceLeagueIds || []));
  const loadSuccess = useSelector(selectDanceLeagueLoadSuccess);

  useEffect(() => {
    if (!danceLeagueCollection.length && danceLeagueIds?.length) {
      dispatch(fetchDanceLeagues(danceLeagueIds));
    }
  }, [danceLeagueIds]);

  const handleChange = (v: AutocompleteResult | AutocompleteResult[]) => {
    const values = v as AutocompleteResult[];
    onChange(values.map(mapToEntity));
  };

  const handleSearch = async (term: string): Promise<AutocompleteResult[]> => {
    return searchEntities(term);
  };

  return (
    <>
      {(!danceLeagueIds?.length || loadSuccess) && (
        <AutocompleteField
          label='Dance league or championship'
          fetchItems={handleSearch}
          onChange={handleChange}
          allowMultiple={true}
          currentValues={danceLeagueCollection.map(mapToAutocomplete)}
        />
      )}
    </>
  );
};

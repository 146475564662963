import React, { FC, useReducer, useEffect, Reducer } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSignInWithEmailAndPassword, useAnalytics } from '@bcx-tech/frontend-core';

import { EmailField } from '@bcx-tech/weave';
import { PasswordField } from '../../components/PasswordField';
import { AuthAlert } from '../../components/AuthAlert';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';

import styles from './Login.module.css';

type FormState = {
  email: string;
  password: string;
};

type Action = {
  type: string;
  value: string;
};

const reducer: Reducer<FormState, Action> = (prevState, action): FormState => {
  switch (action.type) {
    case 'email':
      return {
        ...prevState,
        email: action.value,
      };
    case 'password':
      return {
        ...prevState,
        password: action.value,
      };
  }
  return prevState;
};

export const Login: FC = () => {
  const navigate = useNavigate();
  const { logAnalyticsEvent } = useAnalytics();
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword();

  const handleClick = () => {
    signInWithEmailAndPassword(state.email, state.password);
  };

  useEffect(() => {
    if (user) {
      logAnalyticsEvent('login');
      navigate('/');
    }
  }, [user, navigate]);

  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
  });

  return (
    <>
      <h1 className={styles.title}>Login</h1>
      {error && <AuthAlert error={error} />}
      <Form className={styles.form}>
        <EmailField onChangeText={(text: string) => dispatch({ type: 'email', value: text })} />
        <PasswordField onChangeText={(text: string) => dispatch({ type: 'password', value: text })} />
        <PrimaryButton loading={loading} handleClick={handleClick} label='Log in' />
        <SecondaryButton handleClick={() => navigate('/auth/forgot-password')} label='Forgot password' />
      </Form>
    </>
  );
};

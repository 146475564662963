import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

export const Home: FC = () => {
  const sections = [
    {
      title: 'Competitions and Events Management',
      cards: [
        {
          link: '/events/add',
          title: 'Add an event',
          text: `Add information about an event or competition coming up`,
        },
        {
          link: '/events',
          title: 'View events',
          text: `View the events and competitions`,
        },
        {
          link: '/promoters',
          title: 'View promoters',
          text: 'View and manage event promoters',
        },
        {
          link: '/adjudicators',
          title: 'View Adjudicators and Coaches',
          text: 'View and manage event adjudicators and coaches',
        },
      ],
    },
    {
      title: 'Customer Management',
      cards: [
        {
          link: '/users',
          title: 'Manage users',
          text: 'List users who have signed up to the app',
        },
      ],
    },
  ];

  return (
    <>
      {sections.map((section, idx) => (
        <div key={idx} className='mb-3'>
          <h2>{section.title}</h2>
          <div className='d-flex justify-content-between align-items-stretch'>
            {section.cards.map((card, i) => (
              <Card key={i} className='m-3 w-50 flex-fill'>
                <Card.Title className='mx-3 mt-3'>
                  <Link to={card.link} className={styles.link}>
                    {card.title}
                  </Link>
                </Card.Title>
                <Card.Body>{card.text}</Card.Body>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

import { BaseLinkedEntity } from '@bcx-tech/tbc-types';
import { AutocompleteResult } from '@bcx-tech/weave';
import { useFirestore } from '@bcx-tech/frontend-core';

export const useLinkedEntity = <T extends BaseLinkedEntity>(collection: string) => {
  const client = useFirestore<T>({ collection });
  const mapToAutocomplete = ({ id, name }: T): AutocompleteResult => ({ id, label: name });

  const mapToEntity = ({ id, label, customOption }: AutocompleteResult): BaseLinkedEntity => ({
    id,
    name: label,
    searchableName: label.toLowerCase(),
    isNew: customOption || false,
  });

  const searchEntities = (term: string): Promise<AutocompleteResult[]> =>
    client.searchStartsWith('searchableName', term.toLowerCase()).then((results) => results.map(mapToAutocomplete));

  const fetchMultipleEntities = async (ids: string[]) => {
    const entities = await client.fetchMultiple(ids);
    return entities.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  };

  const storeEntities = async (entities: T[]) => {
    const newRecords = entities.filter((a) => !!a.isNew).map(({ name, searchableName }) => ({ name, searchableName }));
    const existingIds = entities.filter((a) => !a.isNew).map(({ id }) => id);
    const newIds = await Promise.all(newRecords.map((record) => client.create(record as Omit<T, 'id'>)));
    return [...existingIds, ...newIds];
  };

  return { searchEntities, fetchMultipleEntities, mapToAutocomplete, mapToEntity, storeEntities };
};

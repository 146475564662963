import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Badge } from 'react-bootstrap';
import { PrimaryButton } from '../../components/Buttons';
import { FullUser } from '../../@types';

type UserCardProps = {
  user: FullUser;
  showEditButton?: boolean;
  onDeleteClickHandler?: (user: FullUser) => void;
};

export const UserCard: FC<UserCardProps> = ({ user, showEditButton = true, onDeleteClickHandler }) => {
  const navigate = useNavigate();

  const roles = [];
  if (user.isAdmin) {
    roles.push('admin');
  }
  if (user.isPromoter) {
    roles.push('promoter');
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {user.user.firstName} {user.user.lastName}
          {roles.map((role, idx) => (
            <Badge key={idx} className='ms-3'>
              {String(role).toUpperCase()}
            </Badge>
          ))}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Email: <strong>{user.user.email}</strong>
        </Card.Text>
        <Card.Text>
          Enabled: <strong>{user.user.isEnabled ? 'Yes' : 'No'}</strong>
        </Card.Text>
        <Card.Text>
          Is marked for deletion: <strong>{user.user.isDeleted ? 'Yes' : 'No'}</strong>
        </Card.Text>
        <Card.Text>
          Consented to promotional notifications:{' '}
          <strong>{user.user.isOptedIntoPromotionalNotifications ? 'Yes' : 'No'}</strong>
        </Card.Text>
        {showEditButton && (
          <PrimaryButton handleClick={() => navigate(`/users/${user.userAccount.uid}/edit`)} label='Edit user' />
        )}
        {!!onDeleteClickHandler && (
          <a className='btn btn-sm btn-danger' onClick={() => onDeleteClickHandler(user)}>
            Delete user
          </a>
        )}
      </Card.Body>
    </Card>
  );
};

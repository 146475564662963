import React, { FC, useState, useEffect } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { MultiChoiceField, SingleChoiceField } from '@bcx-tech/weave';
import { showDanceOptions } from '@bcx-tech/frontend-core';
import { DanceCategoryOption, DanceEventCategory } from '@bcx-tech/tbc-types';
import { useFormFields } from '../../hooks';

const binaryChoices = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

type EventCategoriesEditProps = {
  categories: DanceCategoryOption[];
  onChange: (values: DanceEventCategory[]) => void;
  currentValues?: DanceEventCategory[];
};

export const EventCategoriesEdit: FC<EventCategoriesEditProps> = ({ categories, onChange, currentValues }) => {
  const [formState, setFormState] = useState<DanceEventCategory[]>(currentValues || []);

  const createChangeHandler = (key: string) => (value: DanceEventCategory) => {
    setFormState((prev) => [...prev.filter((c) => c.category !== key), value]);
  };

  useEffect(() => {
    if (formState.length) {
      onChange(formState);
    }
  }, [formState]);

  return (
    <div className='mb-3'>
      <Form.Label>Event categories:</Form.Label>
      <Accordion defaultActiveKey={currentValues?.map(({ category }) => category) || []}>
        {categories.map((category) => {
          const currentData = currentValues?.find((ec) => ec.category === category.key);
          return (
            <EventCategoryEdit
              onChange={createChangeHandler(category.key)}
              key={category.key}
              category={category}
              currentValues={currentData}
            />
          );
        })}
      </Accordion>
    </div>
  );
};

type FormState = DanceEventCategory;

type EventCategoryEditProps = {
  onChange: (category: DanceEventCategory) => void;
  category: DanceCategoryOption;
  currentValues?: DanceEventCategory;
};

export const EventCategoryEdit: FC<EventCategoryEditProps> = ({ onChange, category, currentValues }) => {
  const { formState, createChangeHandler } = useFormFields<FormState>({ ...currentValues, category: category.key });

  useEffect(() => {
    if (formState.danceStyles?.length) {
      onChange(formState);
    }
  }, [formState]);

  return (
    <Accordion.Item eventKey={category.key} key={category.key}>
      <Accordion.Header>{category.value}</Accordion.Header>
      <Accordion.Body>
        <MultiChoiceField
          type='checkbox'
          label='Dance styles:'
          choices={currentValues?.danceStyles}
          options={category.danceStyles}
          onChoose={createChangeHandler('danceStyles')}
        />
        <MultiChoiceField
          type='checkbox'
          label='Age categories:'
          choices={currentValues?.ageLevels}
          options={category.ageCategories}
          onChoose={createChangeHandler('ageLevels')}
        />
        <SingleChoiceField
          type='radio'
          label='Cash prizes:'
          choice={currentValues?.cashPrizes ? 'true' : 'false'}
          choices={binaryChoices}
          onChoose={createChangeHandler('cashPrizes')}
        />
        <SingleChoiceField
          type='radio'
          label='Show dance events:'
          choice={currentValues?.showDanceEvent ? 'true' : 'false'}
          choices={binaryChoices}
          onChoose={createChangeHandler('showDanceEvent')}
        />
        {formState.showDanceEvent && (
          <div className='ms-4'>
            <MultiChoiceField
              type='checkbox'
              choices={currentValues?.showDanceOptions}
              options={showDanceOptions}
              onChoose={createChangeHandler('showDanceOptions')}
            />
          </div>
        )}
        {['AMATEUR', 'PROFESSIONAL', 'SAMESEX'].includes(category.key) && (
          <SingleChoiceField
            type='radio'
            label='Formation event:'
            choice={currentValues?.formationEvent ? 'true' : 'false'}
            choices={binaryChoices}
            onChoose={createChangeHandler('formationEvent')}
          />
        )}
        <SingleChoiceField
          type='radio'
          label='Does your event include solo rounds?'
          choice={currentValues?.formationEvent ? 'true' : 'false'}
          choices={binaryChoices}
          onChoose={createChangeHandler('hasSoloRounds')}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';

type RowLabelListViewProps = {
  label: string;
  itemArray?: string[];
};

export const RowLabelListView: FC<RowLabelListViewProps> = ({ label, itemArray }) => (
  <>
    {itemArray && itemArray.length && (
      <Row>
        <Col>
          {label}:
          <ul>
            {itemArray.map((item: string, i: number) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </Col>
      </Row>
    )}
  </>
);

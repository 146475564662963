import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { LoggedInUser } from '../../components/LoggedInUser';
import styles from './AdminLayout.module.css';
import { useFirebaseAuthState } from '@bcx-tech/frontend-core';

export const AdminLayout: FC = () => {
  const [user, loading] = useFirebaseAuthState();
  return (
    <>
      <Header>{!loading && !!user && <LoggedInUser user={user} />}</Header>
      <Container className={`${styles.container} admin`}>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

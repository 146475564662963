import { getReferenceItemFeature, LoadStatus } from '@bcx-tech/frontend-core';
import { Adjudicator } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const entityAdapterProps = {
  selectId: (adjudicator: Adjudicator) => adjudicator.id,
  sortComparer: (a: Adjudicator, b: Adjudicator) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  },
};

const referenceItemFeature = getReferenceItemFeature<Adjudicator>('adjudicators', entityAdapterProps);

const { slice, entityAdapter } = referenceItemFeature;

const {
  fetchItems: fetchAdjudicators,
  fetchAllItems,
  fetchItem: fetchAdjudicator,
  createNewItem: createNewAdjudicator,
  updateItem: updateAdjudicator,
  deleteItem,
} = referenceItemFeature;

export { fetchAdjudicators, fetchAllItems, fetchAdjudicator, createNewAdjudicator, updateAdjudicator, deleteItem };

export const selectAdjudicators = (state: RootState, ids: string[]): Adjudicator[] => {
  const adjudicators: Adjudicator[] = [];
  Object.keys(state.adjudicators.entities).forEach((id) => {
    if (ids.includes(id)) {
      adjudicators.push(state.adjudicators.entities[id] as Adjudicator);
    }
  });
  return adjudicators;
};

export const selectAdjudicatorLoadSuccess = (state: RootState) => state.adjudicators.status === LoadStatus.SUCCEEDED;

export const { selectById: selectAdjudicator, selectAll: selectAllAdjudicators } = entityAdapter.getSelectors(
  (state: RootState) => state.adjudicators
);

export const { setItem } = slice.actions;

export default slice.reducer;

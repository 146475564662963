import React, { FC, useState, FormEvent } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Form, Alert, Accordion } from 'react-bootstrap';
import { useFormFields, useAdminApi } from '../../hooks';
import { PrimaryButton } from '../../components/Buttons';
import { UserCard } from '../../components/UserCard';
import { userSchema } from '@bcx-tech/tbc-types';
import { SwitchField, TextField } from '@bcx-tech/weave';
import { FullUser } from '../../@types';

type FormState = FullUser;

export const UsersEdit: FC = () => {
  const fullUser = useLoaderData() as FullUser;
  const { userAccount, user, dancerProfile, isPromoter, isAdmin } = fullUser;
  const { formState: userFormState, createChangeHandler: createUserChangeHandler } = useFormFields<FormState['user']>({
    ...user,
    uid: userAccount.uid,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });
  const [userIsPromoter, setUserIsPromoter] = useState<boolean>(isPromoter);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(isAdmin);
  const { editUser, addPromoterRole, removePromoterRole, makeAdmin } = useAdminApi();

  const togglePromoterRole = async () => {
    if (userIsPromoter) {
      await removePromoterRole(userAccount.uid);
    } else {
      await addPromoterRole(userAccount.uid);
    }
    setUserIsPromoter(!userIsPromoter);
  };

  const makeUserAdmin = async () => {
    await makeAdmin(userAccount.uid);
    setUserIsAdmin(true);
  };

  const onSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { id, ...data } = userFormState;
    const updatedUser = {
      ...data,
      isEnabled: userFormState.isEnabled || false,
      isOptedIntoPromotionalNotifications: userFormState.isOptedIntoPromotionalNotifications || false,
      isDeleted: userFormState.isDeleted || false,
      dateCreated: userFormState.dateCreated || new Date().getTime(),
      dateUpdated: new Date().getTime(),
    };
    const { error } = userSchema.validate(updatedUser);
    if (error) {
      setError({ isError: true, message: `Error updating user ${id}: ${error}` });
    }
    try {
      await editUser(userAccount.uid, { user: updatedUser, dancerProfile });
    } catch (error) {
      const { message } = error as Error;
      setError({ isError: true, message: `Error updating user: ${message}` });
    }
    setLoading(false);
  };

  const onReset = async (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <h1>Edit User</h1>
      {error.isError && <Alert variant='danger'>{error.message}</Alert>}
      <UserCard
        user={{ userAccount, user, dancerProfile, isPromoter: userIsPromoter, isAdmin: userIsAdmin }}
        showEditButton={false}
      ></UserCard>
      <Form onSubmit={onSubmit} onReset={onReset}>
        <Accordion>
          <Accordion.Item eventKey='permissions'>
            <Accordion.Header>Access and Permissions</Accordion.Header>
            <Accordion.Body>
              <PrimaryButton
                label={userIsPromoter ? 'Remove promoter role' : 'Add promoter role'}
                handleClick={togglePromoterRole}
              ></PrimaryButton>
              {!userIsAdmin && <PrimaryButton label='Make user an admin' handleClick={makeUserAdmin}></PrimaryButton>}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='basicinfo'>
            <Accordion.Header>Edit Basic Info</Accordion.Header>
            <Accordion.Body>
              <TextField
                text={userFormState?.firstName}
                label='First name'
                required
                onChangeText={createUserChangeHandler('firstName')}
              />
              <TextField
                text={userFormState?.lastName}
                label='Last name'
                required
                onChangeText={createUserChangeHandler('lastName')}
              />
              <TextField
                text={userFormState?.email}
                label='Email address'
                required
                onChangeText={createUserChangeHandler('email')}
              />
              <TextField
                text={userFormState?.termsVersionAgreed}
                label='Version of terms agreed to'
                required
                onChangeText={createUserChangeHandler('termsVersionAgreed')}
              />
              <SwitchField
                choice={userFormState?.isOptedIntoPromotionalNotifications}
                label='User has consented to receiving promotional notifications'
                onChoose={createUserChangeHandler('isOptedIntoPromotionalNotifications')}
              />
              <SwitchField
                choice={userFormState?.isEnabled}
                label='User account is enabled'
                onChoose={createUserChangeHandler('isEnabled')}
              />
              <SwitchField
                choice={userFormState?.isDeleted}
                label='User account is marked for deletion'
                onChoose={createUserChangeHandler('isDeleted')}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <PrimaryButton loading={loading} type='submit' label='Update User' />
      </Form>
    </>
  );
};

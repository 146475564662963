import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './Header.module.css';

export const Header: FC = ({ children }) => (
  <Navbar expand={'lg'} className={styles.header}>
    <Navbar.Brand>
      <h1 className={styles.title}>
        <Link to={`/`}>
          The Ballroom Connection
          <br />
          Administration
        </Link>
      </h1>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls='basic-navbar-nav' />
    <Navbar.Collapse id='basic-navbar-nav'>
      <Nav className='ml-auto'>
        <LinkContainer to={`/`}>
          <Nav.Link>Home</Nav.Link>
        </LinkContainer>
        <LinkContainer to={`/events`}>
          <Nav.Link>Events</Nav.Link>
        </LinkContainer>
        <LinkContainer to={`/promoters`}>
          <Nav.Link>Promoters</Nav.Link>
        </LinkContainer>
        <LinkContainer to={`/adjudicators`}>
          <Nav.Link>Adjudicators</Nav.Link>
        </LinkContainer>
      </Nav>
      {children}
    </Navbar.Collapse>
  </Navbar>
);

import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { CoachesView } from './CoachesView';

type TrainingCampViewProps = {
  event: DanceEvent;
};

const TrainingCampView: FC<TrainingCampViewProps> = ({ event }) => {
  return (
    <>
      <h4>Information about this training camp</h4>
      {event.pricingDetails && (
        <Row>
          <Col>Price details:</Col>
          <Col sm={8}>{event.pricingDetails}</Col>
        </Row>
      )}
      {!!event.coaches?.length && <CoachesView coachIds={event.coaches}></CoachesView>}
    </>
  );
};

export default TrainingCampView;

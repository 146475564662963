import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';

type RowLabelViewProps = {
  label: string;
};

export const RowLabelView: FC<RowLabelViewProps> = ({ label, children }) => (
  <>
    {children && (
      <Row>
        <Col>{label}:</Col>
        <Col sm={8}>{children}</Col>
      </Row>
    )}
  </>
);

import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { AddressView, FileReference } from '@bcx-tech/weave';
import { selectEvent, fetchEvent } from '../../features/eventsSlice';
import { PromotersView } from './PromotersView';
import CompetitionView from './CompetitionView';
import TrainingCampView from './TrainingCampView';
import { ConfirmEventDelete } from '../../components/ConfirmEventDelete';
import { RowLabelView } from '../../components/RowLabelView';
import { DateView } from '../../components/DateView';
import { PrimaryButton } from '../../components/Buttons';

import { RootState } from '../../store';

export const EventView: FC = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { eventId = '' } = params;
  const event: DanceEvent | undefined = useSelector((state: RootState) => selectEvent(state, eventId));

  useEffect(() => {
    if (!event && eventId) {
      dispatch(fetchEvent(eventId));
    }
  }, [eventId]);

  const handleConfirm = () => {
    setShow(false);
    navigate('/events');
  };

  return (
    <>
      {event && (
        <>
          <Row className='mb-4'>
            <Col sm={8}>
              <h1>{event.title}</h1>
              {!!event.promoters?.length && <PromotersView promoterIds={event.promoters}></PromotersView>}
              <DateView startDate={event.startDate} endDate={event.endDate}></DateView>
              {event.time && (
                <div>
                  {' '}
                  {event.time}
                  {event.endTime && <span> - {event.endTime}</span>}
                </div>
              )}
              <div className='btn-group'>
                <Link className='btn btn-sm btn-outline-info' to={`/events/${event.id}/edit`}>
                  Edit
                </Link>
                <a className='btn btn-sm btn-outline-info' onClick={() => setShow(true)}>
                  Delete
                </a>
              </div>
            </Col>
            <Col sm={4}>
              {event.image && <img className='img-fluid' src={event.image.url} alt={event.title} title={event.title} />}
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm={8}>
              <div className='mb-4'>{event.description}</div>
              {event.eventType === 'COMPETITION' && <CompetitionView event={event}></CompetitionView>}
              {event.eventType === 'TRAININGCAMP' && <TrainingCampView event={event}></TrainingCampView>}
            </Col>
            <Col sm={4}>
              {event.address && (
                <div className='mb-4'>
                  <h4>Location</h4>
                  <AddressView address={event.address}></AddressView>
                </div>
              )}
              <div className='mb-4'>
                <h4>Contact</h4>
                <RowLabelView label='Website'>
                  <a href='event.website' target='_blank'>
                    {event.website}
                  </a>
                </RowLabelView>
                <RowLabelView label='Email'>
                  <a href={`mailto:${event.contactEmail}`}>{event.contactEmail}</a>
                </RowLabelView>
                <RowLabelView label='Phone'>{event.contactPhone}</RowLabelView>
              </div>
              {event.attachments && !!event.attachments.length && (
                <div className='mb-4'>
                  <h4>More information</h4>
                  {event.attachments.map((item: FileReference, i: number) => (
                    <Row key={i} className='p-3'>
                      <Col>
                        <a href={item.url} target='_blank' rel='noreferrer'>
                          {item.name}
                        </a>
                      </Col>
                    </Row>
                  ))}
                </div>
              )}
            </Col>
          </Row>
          <PrimaryButton label='Back to Events' handleClick={() => navigate('/events')} />
          <ConfirmEventDelete
            eventToDelete={event}
            handleConfirm={handleConfirm}
            handleClose={() => setShow(false)}
            show={show}
          />
        </>
      )}
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPromoters, fetchPromoters } from '../../features/promotersSlice';
import { RowLabelListView } from '../../components/RowLabelListView';

import { RootState } from '../../store';

type PromotersViewProps = {
  promoterIds: string[];
};
export const PromotersView: FC<PromotersViewProps> = ({ promoterIds }) => {
  const dispatch = useDispatch();
  const promoterCollection = useSelector((state: RootState) => selectPromoters(state, promoterIds || []));

  useEffect(() => {
    if (!promoterCollection.length) {
      dispatch(fetchPromoters(promoterIds));
    }
  }, [promoterIds]);

  return (
    <>
      {!!promoterCollection?.length && (
        <RowLabelListView label='Promoted by' itemArray={promoterCollection.map(({ name }) => name)} />
      )}
    </>
  );
};

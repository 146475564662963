import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { isValid, parse, format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

type TimeFieldProps = {
  onChange: (value: string) => void;
  label?: string;
  time?: string;
  className?: string;
};

const TimeField: FC<TimeFieldProps> = ({ onChange, label, time, className }) => {
  const [startDate, setStartDate] = useState(time ? parse(time, 'HH:mm', new Date()) : null);
  const classMap = ['time-field', 'mb-3'];
  if (className) {
    classMap.push(className);
  }

  const onDateInput = (date: Date) => {
    if (isValid(date)) {
      setStartDate(date);
      onChange(format(date, 'HH:mm'));
    }
  };

  return (
    <div className={classMap.join(' ')}>
      {label && <Form.Label>{label}</Form.Label>}
      <DatePicker
        data-testid='timefield-input'
        selected={startDate}
        onChange={onDateInput}
        showTimeSelect
        showTimeSelectOnly
        timeCaption='Time'
        dateFormat='h:mm aa'
      />
    </div>
  );
};

export default TimeField;

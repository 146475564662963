import React, { FC, FormEvent, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Form, Accordion, Alert } from 'react-bootstrap';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { Adjudicator } from '@bcx-tech/tbc-types';
import { AutocompleteField, AutocompleteResult, Loader } from '@bcx-tech/weave';
import {
  selectFilteredEvents,
  selectEventsStatus,
  searchResultsCleared,
  searchByAdjudicatorId,
} from '../../features/eventsSlice';
import { fetchAdjudicator, selectAdjudicator, setItem } from '../../features/adjudicatorsSlice';
import { EventsListTable } from './EventsListTable';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { useLinkedEntity } from '../../hooks';
import { EventsListProps } from './@types';

import { RootState } from '../../store';

type FormState = {
  adjudicator: Adjudicator;
};

export const EventsListByAdjudicator: FC<EventsListProps> = ({ confirmDeleteHandler }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const eventsStatus = useSelector(selectEventsStatus);
  const events = useSelector(selectFilteredEvents);
  const { mapToEntity, searchEntities } = useLinkedEntity<Adjudicator>('judges-coaches');
  const [adjudicatorId, setAdjudicatorId] = useState<string | undefined>(
    searchParams.get('adjudicatorId') || undefined
  );
  const adjudicator: Adjudicator | undefined = useSelector((state: RootState) =>
    selectAdjudicator(state, adjudicatorId || '')
  );
  const [showSearch] = useState<string | undefined>(adjudicatorId ? undefined : '0');
  const [formState, setFormState] = useState<FormState>();

  useEffect(() => {
    if (!adjudicator && adjudicatorId) {
      dispatch(fetchAdjudicator(adjudicatorId));
    }
    if (adjudicator) {
      dispatch(searchByAdjudicatorId(adjudicator));
    }
  }, [adjudicatorId, adjudicator]);

  const handleChange = (v: AutocompleteResult | AutocompleteResult[]) => {
    if (v) {
      const value = v as AutocompleteResult;
      const adjudicator = mapToEntity(value);
      setFormState({ adjudicator });
    }
  };

  const handleSearch = async (term: string): Promise<AutocompleteResult[]> => {
    return searchEntities(term);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formState) {
      const { adjudicator } = formState;
      setAdjudicatorId(adjudicator.id);
      dispatch(setItem(adjudicator));
    }
  };

  const onReset = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAdjudicatorId(undefined);
    dispatch(searchResultsCleared());
  };

  return (
    <>
      <h1>Events by Adjudicator</h1>
      <Accordion defaultActiveKey={showSearch}>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Search for an adjudicator</Accordion.Header>
          <Accordion.Body>
            <Form noValidate onSubmit={onSubmit} onReset={onReset}>
              <AutocompleteField
                label='Search for an adjudicator'
                allowNew={false}
                fetchItems={handleSearch}
                onChange={handleChange}
                allowMultiple={false}
              />
              <PrimaryButton type='submit' label='Show events' />
              <SecondaryButton type='reset' label='Clear' />
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {eventsStatus === LoadStatus.LOADING && (
        <div className='mb-3 d-flex justify-content-center'>
          <Loader></Loader>
        </div>
      )}
      {eventsStatus !== LoadStatus.LOADING && adjudicator && (
        <>
          <h2>Competitions adjudicated by {adjudicator.name}</h2>
          {!!events.length && <EventsListTable events={events} onDeleteClickHandler={confirmDeleteHandler} />}
          {!events.length && <Alert variant='warning'>No events adjudicated by {adjudicator.name}</Alert>}
        </>
      )}
    </>
  );
};

import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styles from './SigninLayout.module.css';

export const SigninLayout: FC = () => (
  <Container className={styles.container}>
    <Outlet />
  </Container>
);

import React from 'react';
import { Route, redirect, createRoutesFromElements } from 'react-router-dom';

import { AdminLayout } from './layouts/AdminLayout';
import { SigninLayout } from './layouts/SigninLayout';
import { Home } from './screens/Home';
import { NotFoundPage } from './screens/NotFoundPage';
import { EventsList } from './screens/EventsList';
import { EventsEdit } from './screens/EventsEdit';
import { EventView } from './screens/EventView';
import { Login } from './screens/Login';
import { PromotersList } from './screens/PromotersList';
import { PromotersEdit } from './screens/PromotersEdit';
import { User } from 'firebase/auth';
import { ForgotPassword } from './screens/ForgotPassword';
import { Settings } from './screens/Settings';
import { ChangePassword } from './screens/ChangePassword';
import { ManageUsers } from './screens/ManageUsers';
import { UsersEdit } from './screens/UsersEdit';
import { AdjudicatorsList } from './screens/AdjudicatorsList';
import { AdjudicatorsEdit } from './screens/AdjudicatorsEdit';

import { RouterErrorBoundary } from './components/RouterErrorBoundary';
import { FullUser } from './@types';

const loader = async (user?: User | null) => {
  if (!user) {
    return redirect('/auth/login');
  }
  return null;
};

type RoutesProps = {
  user?: User | null | undefined;
  getUser: (id: string) => Promise<FullUser>;
};

export const getRoutes = ({ user, getUser }: RoutesProps) =>
  createRoutesFromElements(
    <Route>
      <Route
        path='/'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path='/events'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<EventsList />} />
        <Route path='add' element={<EventsEdit />} />
        <Route path=':eventId/edit' element={<EventsEdit />} />
        <Route path=':eventId' element={<EventView />} />
      </Route>
      <Route
        path='promoters'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<PromotersList />} />
        <Route path='add' element={<PromotersEdit />} />
        <Route path=':promoterId/edit' element={<PromotersEdit />} />
      </Route>
      <Route
        path='adjudicators'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<AdjudicatorsList />} />
        <Route path='add' element={<AdjudicatorsEdit />} />
        <Route path=':adjudicatorId/edit' element={<AdjudicatorsEdit />} />
      </Route>
      <Route path='/auth' element={<SigninLayout />} errorElement={<RouterErrorBoundary />}>
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
      </Route>
      <Route
        path='/settings'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<Settings />} />
        <Route path='change-password' element={<ChangePassword />} />
      </Route>
      <Route
        path='/users'
        element={<AdminLayout />}
        loader={async () => await loader(user)}
        errorElement={<RouterErrorBoundary />}
      >
        <Route index element={<ManageUsers />} />
        <Route
          path=':userId/edit'
          element={<UsersEdit />}
          loader={async ({ params }) => {
            if (!params || !params.userId) {
              return redirect('/users');
            }
            return getUser(params.userId);
          }}
        />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  );

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@bcx-tech/weave';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { selectEvents, selectEventsStatus, fetchEvents } from '../../features/eventsSlice';
import { EventsListTable } from './EventsListTable';
import { PrimaryButton } from '../../components/Buttons';
import { EventsListProps } from './@types';

export const EventsListAll: FC<EventsListProps> = ({ confirmDeleteHandler }) => {
  const dispatch = useDispatch();
  const eventsStatus = useSelector(selectEventsStatus);
  const events = useSelector(selectEvents);

  const handleLoadMore = () => {
    dispatch(fetchEvents());
  };

  useEffect(() => {
    if (eventsStatus === 'idle') {
      dispatch(fetchEvents());
    }
  }, [eventsStatus]);

  return (
    <>
      <h1>Events</h1>
      {eventsStatus === LoadStatus.LOADING && (
        <div className='mb-3 d-flex justify-content-center'>
          <Loader></Loader>
        </div>
      )}
      {!!events.length && (
        <>
          <EventsListTable events={events} onDeleteClickHandler={confirmDeleteHandler} />
          <div className='mb-3 d-flex justify-content-center'>
            <PrimaryButton label='Load more' handleClick={handleLoadMore} />
          </div>
        </>
      )}
    </>
  );
};

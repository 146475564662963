import { getReferenceItemFeature, LoadStatus } from '@bcx-tech/frontend-core';
import { Promoter } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const entityAdapterProps = {
  selectId: (promoter: Promoter) => promoter.id,
};

const referenceItemFeature = getReferenceItemFeature<Promoter>('promoters', entityAdapterProps);

const { slice, entityAdapter } = referenceItemFeature;

export const {
  fetchItems: fetchPromoters,
  fetchAllItems,
  fetchItem: fetchPromoter,
  createNewItem: createNewPromoter,
  updateItem: updatePromoter,
  deleteItem,
} = referenceItemFeature;

export const selectPromoters = (state: RootState, ids: string[]): Promoter[] => {
  const promoters: Promoter[] = [];
  Object.keys(state.promoters.entities).forEach((id) => {
    if (ids.includes(id)) {
      promoters.push(state.promoters.entities[id] as Promoter);
    }
  });
  return promoters;
};

export const selectPromoterLoadSuccess = (state: RootState) => state.promoters.status === LoadStatus.SUCCEEDED;

export const { selectById: selectPromoter, selectAll: selectAllPromoters } = entityAdapter.getSelectors(
  (state: RootState) => state.promoters
);

export const { setItem } = slice.actions;

export default slice.reducer;

import React, { FC, ChangeEvent } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

type PasswordFieldProps = {
  onChangeText: (value: string) => void;
  placeholder?: string;
};

export const PasswordField: FC<PasswordFieldProps> = ({ onChangeText, placeholder = 'Password' }) => {
  const onPasswordInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    onChangeText(target.value);
  };

  return (
    <FormGroup className='mb-3'>
      <FormControl
        type='password'
        placeholder={placeholder}
        aria-label={placeholder}
        onChange={onPasswordInput}
        autoComplete='current-password'
      />
    </FormGroup>
  );
};

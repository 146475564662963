import React, { FC } from 'react';
import { BsCheck, BsX } from 'react-icons/bs';
import styles from './BooleanView.module.css';

type BooleanViewProps = {
  value: boolean;
  label: string;
};

export const BooleanView: FC<BooleanViewProps> = ({ value, label }) => {
  return (
    <div className='boolean-view'>
      {value ? <BsCheck className={styles.check} size={36} /> : <BsX className={styles.cross} size={36} />} {label}
    </div>
  );
};

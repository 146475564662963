import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Footer.module.css';

export const Footer: FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <Col>
            <p>
              The Ballroom Connection
              <br />
              <a href='mailto:info@theballroomconnection.com'>info@theballroomconnection.com</a>
            </p>
          </Col>
          <Col>
            <ul>
              <li>Facebook</li>
              <li>Instagram</li>
            </ul>
          </Col>
        </Row>
        <div className={styles.legal}>
          <p>Copyright {year} The Ballroom Connection Limited. All Rights Reserved</p>
        </div>
      </Container>
    </footer>
  );
};

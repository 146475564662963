import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';
import { CustomAuthErrorCodes } from '../../interfaces';
import { FirebaseError } from 'firebase/app';

type AuthAlertProps = {
  error: FirebaseError | Error;
};

export const AuthAlert: FC<AuthAlertProps> = ({ error }) => {
  let errorMessage: string;
  const code = error instanceof FirebaseError ? error.code : 'OTHER';
  switch (code) {
    case CustomAuthErrorCodes.INVALID_EMAIL:
      errorMessage = 'Enter a valid email address';
      break;
    case CustomAuthErrorCodes.INTERNAL_ERROR:
    case CustomAuthErrorCodes.INVALID_PASSWORD:
      errorMessage = 'Incorrect email address and/or password';
      break;
    case CustomAuthErrorCodes.USER_NOT_AUTHORIZED:
      errorMessage = 'You are not authorised to log in';
      break;
    case CustomAuthErrorCodes.USER_DELETED:
      errorMessage = 'An account with this email does not exist. Please check the email address and try again';
      break;
    default:
      errorMessage = 'An error has occurred. Please try again later';
      break;
  }

  return <Alert variant='danger'>{errorMessage}</Alert>;
};

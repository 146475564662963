import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { useFirebaseAuthState, useFirestore, eventTypeChoices, categoriesList } from '@bcx-tech/frontend-core';
import { useAdminApi } from './hooks';
import { getRoutes } from './routes';
import { rootReducer } from './store';
import {
  getDanceEventSchema,
  Adjudicator,
  Coach,
  DanceEvent,
  DanceLeague,
  Promoter,
  baseLinkedEntitySchema,
} from '@bcx-tech/tbc-types';

import './App.css';

const eventTypes = eventTypeChoices.map(({ value }) => value);
const categories = categoriesList.map(({ key }) => key);
const schema = getDanceEventSchema({ eventTypes, categories });

const validateEvent = (event: DanceEvent | Omit<DanceEvent, 'id'>): DanceEvent | Omit<DanceEvent, 'id'> => {
  const { value, error } = schema.validate(event, { stripUnknown: true });
  if (error) {
    throw error;
  }
  return value;
};

const validatePromoter = (promoter: Promoter | Omit<Promoter, 'id'>): Promoter | Omit<Promoter, 'id'> => {
  const { value, error } = baseLinkedEntitySchema.validate(promoter, { stripUnknown: true });
  if (error) {
    throw error;
  }
  return value;
};

const validateDanceLeague = (
  danceLeague: DanceLeague | Omit<DanceLeague, 'id'>
): DanceLeague | Omit<DanceLeague, 'id'> => {
  const { value, error } = baseLinkedEntitySchema.validate(danceLeague, { stripUnknown: true });
  if (error) {
    throw error;
  }
  return value;
};

const validateCoach = (coach: Coach | Omit<Coach, 'id'>): Coach | Omit<Coach, 'id'> => {
  const { value, error } = baseLinkedEntitySchema.validate(coach, { stripUnknown: true });
  if (error) {
    throw error;
  }
  return value;
};

const validateAdjudicator = (
  adjudicator: Adjudicator | Omit<Adjudicator, 'id'>
): Adjudicator | Omit<Adjudicator, 'id'> => {
  const { value, error } = baseLinkedEntitySchema.validate(adjudicator, { stripUnknown: true });
  if (error) {
    throw error;
  }
  return value;
};

function App() {
  const [user, loading] = useFirebaseAuthState();
  const { getUser } = useAdminApi();
  const router = !loading ? createBrowserRouter(getRoutes({ user, getUser })) : null;
  const eventsFirestoreClient = useFirestore<DanceEvent>({ collection: 'events' });
  const adjudicatorsFirestoreClient = useFirestore<Adjudicator>({ collection: 'judges-coaches' });
  const coachesFirestoreClient = useFirestore<Coach>({ collection: 'judges-coaches' });
  const promotersFirestoreClient = useFirestore<Promoter>({ collection: 'promoters' });
  const danceLeaguesFirestoreClient = useFirestore<DanceLeague>({ collection: 'dance-leagues' });

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            danceEvents: {
              client: eventsFirestoreClient,
              validator: validateEvent,
            },
            adjudicators: {
              client: adjudicatorsFirestoreClient,
              validator: validateAdjudicator,
            },
            coaches: {
              client: coachesFirestoreClient,
              validator: validateCoach,
            },
            promoters: {
              client: promotersFirestoreClient,
              validator: validatePromoter,
            },
            danceLeagues: {
              client: danceLeaguesFirestoreClient,
              validator: validateDanceLeague,
            },
          },
        },
      }),
  });

  if (!loading) {
    if (user && user.email) {
      window.newrelic.setUserId(user.uid);
    }
  }

  return <Provider store={store}>{!!router && <RouterProvider router={router} />}</Provider>;
}

export default App;

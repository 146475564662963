import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Coach } from '@bcx-tech/tbc-types';
import { selectCoaches, fetchCoaches, selectCoachLoadSuccess } from '../../features/coachesSlice';
import { useLinkedEntity } from '../../hooks';

import { AutocompleteField, AutocompleteResult } from '@bcx-tech/weave';
import { RootState } from '../../store';

type CoachesEditProps = {
  coachIds?: string[];
  onChange: (coaches: Coach[]) => void;
};

export const CoachesEdit: FC<CoachesEditProps> = ({ coachIds, onChange }) => {
  const dispatch = useDispatch();
  const { mapToAutocomplete, mapToEntity, searchEntities } = useLinkedEntity<Coach>('judges-coaches');

  const coachCollection = useSelector((state: RootState) => selectCoaches(state, coachIds || []));
  const loadSuccess = useSelector(selectCoachLoadSuccess);

  useEffect(() => {
    if (!coachCollection.length && coachIds?.length) {
      dispatch(fetchCoaches(coachIds));
    }
  }, [coachIds]);

  const handleChange = (v: AutocompleteResult | AutocompleteResult[]) => {
    const values = v as AutocompleteResult[];
    onChange(values.map(mapToEntity));
  };

  const handleSearch = async (term: string): Promise<AutocompleteResult[]> => {
    return searchEntities(term);
  };

  return (
    <>
      {(!coachIds?.length || loadSuccess) && (
        <AutocompleteField
          label='Coaches / Instructors'
          fetchItems={handleSearch}
          onChange={handleChange}
          currentValues={coachCollection.map(mapToAutocomplete)}
        />
      )}
    </>
  );
};

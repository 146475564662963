import { combineReducers, configureStore } from '@reduxjs/toolkit';
import eventsReducer from './features/eventsSlice';
import adjudicatorsReducer from './features/adjudicatorsSlice';
import coachesReducer from './features/coachesSlice';
import danceLeaguesReducer from './features/danceLeaguesSlice';
import promotersReducer from './features/promotersSlice';

export const rootReducer = combineReducers({
  events: eventsReducer,
  adjudicators: adjudicatorsReducer,
  coaches: coachesReducer,
  promoters: promotersReducer,
  danceLeagues: danceLeaguesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCoaches, fetchCoaches } from '../../features/coachesSlice';
import { RowLabelListView } from '../../components/RowLabelListView';

import { RootState } from '../../store';

type CoachesViewProps = {
  coachIds: string[];
};
export const CoachesView: FC<CoachesViewProps> = ({ coachIds }) => {
  const dispatch = useDispatch();
  const coachCollection = useSelector((state: RootState) => selectCoaches(state, coachIds || []));

  useEffect(() => {
    if (!coachCollection.length) {
      dispatch(fetchCoaches(coachIds));
    }
  }, [coachIds]);

  return (
    <>
      {!!coachCollection?.length && (
        <RowLabelListView label='Coaches' itemArray={coachCollection.map(({ name }) => name)} />
      )}
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Adjudicator } from '@bcx-tech/tbc-types';
import { selectAdjudicators, selectAdjudicatorLoadSuccess, fetchAdjudicators } from '../../features/adjudicatorsSlice';
import { useLinkedEntity } from '../../hooks';

import { AutocompleteField, AutocompleteResult } from '@bcx-tech/weave';
import { RootState } from '../../store';

type AdjudicatorsEditProps = {
  adjudicatorIds?: string[];
  onChange: (adjudicators: Adjudicator[]) => void;
};

export const AdjudicatorsEdit: FC<AdjudicatorsEditProps> = ({ adjudicatorIds, onChange }) => {
  const dispatch = useDispatch();
  const { searchEntities, mapToAutocomplete, mapToEntity } = useLinkedEntity<Adjudicator>('judges-coaches');

  const adjudicatorCollection = useSelector((state: RootState) => selectAdjudicators(state, adjudicatorIds || []));
  const loadSuccess = useSelector(selectAdjudicatorLoadSuccess);

  useEffect(() => {
    if (!adjudicatorCollection.length && adjudicatorIds?.length) {
      dispatch(fetchAdjudicators(adjudicatorIds));
    }
  }, [adjudicatorIds]);

  const handleChange = (v: AutocompleteResult | AutocompleteResult[]) => {
    const values = v as AutocompleteResult[];
    onChange(values.map(mapToEntity));
  };

  const handleSearch = async (term: string): Promise<AutocompleteResult[]> => {
    return searchEntities(term);
  };

  return (
    <>
      {(!adjudicatorIds?.length || loadSuccess) && (
        <AutocompleteField
          label='Adjudicators'
          fetchItems={handleSearch}
          onChange={handleChange}
          currentValues={adjudicatorCollection.map(mapToAutocomplete)}
        />
      )}
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Loader } from '@bcx-tech/weave';
import { Promoter } from '@bcx-tech/tbc-types';
import { useAnalytics } from '@bcx-tech/frontend-core';
import { selectAllPromoters, fetchAllItems, selectPromoterLoadSuccess } from '../../features/promotersSlice';

export const PromotersList: FC = () => {
  const dispatch = useDispatch();
  const promoters = useSelector(selectAllPromoters);
  const loadSuccess = useSelector(selectPromoterLoadSuccess);
  const { logAnalyticsEvent } = useAnalytics();

  logAnalyticsEvent('page_view', {
    page_title: 'PromotersList',
  });

  useEffect(() => {
    if (!loadSuccess) {
      dispatch(fetchAllItems({ sortOrder: { field: 'name', direction: 'asc' }, itemLimit: 25 }));
    }
  }, [loadSuccess, dispatch]);

  return (
    <>
      <h1>Promoters</h1>
      {!loadSuccess && (
        <div className='mb-3 d-flex justify-content-center'>
          <Loader></Loader>
        </div>
      )}
      <Link className='btn btn-primary' to={`/promoters/add`}>
        Add a new promoter
      </Link>
      {!!promoters.length && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {promoters.map((promoter: Promoter, idx: number) => (
                <tr key={idx}>
                  <td>{promoter.name}</td>
                  <td>
                    <div className='btn-group'>
                      <Link
                        className='btn btn-sm btn-outline-info'
                        to={`/events?tab=byPromoter&promoterId=${promoter.id}`}
                      >
                        View Events
                      </Link>
                      <Link className='btn btn-sm btn-outline-info' to={`/promoters/${promoter.id}/edit`}>
                        Edit
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Promoter } from '@bcx-tech/tbc-types';
import { useLinkedEntity } from '../../hooks';
import { fetchPromoters, selectPromoters, selectPromoterLoadSuccess } from '../../features/promotersSlice';

import { AutocompleteField, AutocompleteResult } from '@bcx-tech/weave';
import { RootState } from '../../store';

type PromotersEditProps = {
  promoterIds?: string[];
  onChange: (promoters: Promoter[]) => void;
};

export const PromotersEdit: FC<PromotersEditProps> = ({ promoterIds, onChange }) => {
  const dispatch = useDispatch();
  const { mapToAutocomplete, mapToEntity, searchEntities } = useLinkedEntity<Promoter>('promoters');

  const promoterCollection: Promoter[] = useSelector((state: RootState) => selectPromoters(state, promoterIds || []));
  const loadSuccess = useSelector(selectPromoterLoadSuccess);

  useEffect(() => {
    if (!promoterCollection.length && promoterIds) {
      dispatch(fetchPromoters(promoterIds));
    }
  }, [promoterIds, dispatch]);

  const handleChange = (v: AutocompleteResult | AutocompleteResult[]) => {
    const values = v as AutocompleteResult[];
    onChange(values.map(mapToEntity));
  };

  const handleSearch = async (term: string): Promise<AutocompleteResult[]> => {
    return searchEntities(term);
  };

  return (
    <>
      {(!promoterIds?.length || loadSuccess) && (
        <AutocompleteField
          label='Promoters/Organisers'
          fetchItems={handleSearch}
          onChange={handleChange}
          allowMultiple={true}
          currentValues={promoterCollection.map(mapToAutocomplete)}
        />
      )}
    </>
  );
};

import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Loader } from '@bcx-tech/weave';
import { Adjudicator } from '@bcx-tech/tbc-types';
import { useAnalytics } from '@bcx-tech/frontend-core';
import { selectAllAdjudicators, fetchAllItems, selectAdjudicatorLoadSuccess } from '../../features/adjudicatorsSlice';
import { PrimaryButton } from '../../components/Buttons';

export const AdjudicatorsList: FC = () => {
  const dispatch = useDispatch();
  const adjudicators = useSelector(selectAllAdjudicators);
  const loadSuccess = useSelector(selectAdjudicatorLoadSuccess);
  const { logAnalyticsEvent } = useAnalytics();

  logAnalyticsEvent('page_view', {
    page_title: 'AdjudicatorsList',
  });

  useEffect(() => {
    if (!loadSuccess) {
      dispatch(fetchAllItems({ sortOrder: { field: 'name', direction: 'asc' }, itemLimit: 50 }));
    }
  }, [loadSuccess, dispatch]);

  const handleLoadMore = () => {
    dispatch(fetchAllItems({ sortOrder: { field: 'name', direction: 'asc' }, itemLimit: 50 }));
  };

  return (
    <>
      <h1>Adjudicators and Coaches</h1>
      {!loadSuccess && (
        <div className='mb-3 d-flex justify-content-center'>
          <Loader></Loader>
        </div>
      )}
      <Link className='btn btn-primary' to={`/adjudicators/add`}>
        Add a new adjudicator/coach
      </Link>
      {!!adjudicators.length && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {adjudicators.map((adjudicator: Adjudicator, idx: number) => (
                <tr key={idx}>
                  <td>{adjudicator.name}</td>
                  <td>
                    <div className='btn-group'>
                      <Link
                        className='btn btn-sm btn-outline-info'
                        to={`/events?tab=byAdjudicator&adjudicatorId=${adjudicator.id}`}
                      >
                        View Competitions
                      </Link>
                      <Link
                        className='btn btn-sm btn-outline-info'
                        to={`/events?tab=byCoach&coachId=${adjudicator.id}`}
                      >
                        View Training Camps
                      </Link>
                      <Link className='btn btn-sm btn-outline-info' to={`/adjudicators/${adjudicator.id}/edit`}>
                        Edit
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='mb-3 d-flex justify-content-center'>
            <PrimaryButton label='Load more' handleClick={handleLoadMore} />
          </div>
        </>
      )}
    </>
  );
};

import React, { FC, ChangeEvent, useState, forwardRef } from 'react';
import { Form, InputGroup, Button, FormControlProps, ButtonProps } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { BsCalendarEventFill } from 'react-icons/bs/index.js';
import { isValid, parse } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
registerLocale('en-GB', enGB);

import 'react-datepicker/dist/react-datepicker.css';

type DateFieldProps = {
  onChange: (value: number) => void;
  label?: string;
  date?: number;
  required?: boolean;
  className?: string;
};

const errors = {
  required: 'This field is required',
  invalid: 'Please enter a valid date',
};

type CustomDateInputProps = FormControlProps & {
  isInvalid: boolean;
  error?: string;
};

const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>((props, ref) => {
  const { isInvalid, ...buttonProps } = props;
  return (
    <InputGroup className='mb-3' hasValidation>
      <Form.Control
        {...props}
        data-testid='datefield-input'
        placeholder='DD/MM/YYYY'
        aria-label='Date'
        aria-describedby='date-icon'
        size={'sm'}
        ref={ref}
        isInvalid={isInvalid}
      />
      <Button variant='outline-secondary' id='date-icon' {...(buttonProps as ButtonProps)}>
        <BsCalendarEventFill />
      </Button>
      <Form.Control.Feedback type='invalid' data-testid='err-msg'>
        {props.error}
      </Form.Control.Feedback>
    </InputGroup>
  );
});
CustomDateInput.displayName = 'CustomDateInput';

const DateField: FC<DateFieldProps> = ({ onChange, label, date, required = false, className }) => {
  const [startDate, setStartDate] = useState(date ? new Date(date) : null);
  const [invalid, setInvalid] = useState(false);
  const [error, setError] = useState<string>();
  const classMap = ['date-field', 'mb-3'];
  if (className) {
    classMap.push(className);
  }

  const onDateInput = (date: Date) => {
    setStartDate(date);
    if (isValid(date)) {
      onChange(date.getTime());
      setError(undefined);
      setInvalid(false);
    }
  };
  const handleOnBlur = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const date = parse(target.value, 'dd/MM/yyyy', new Date());

    if (!target.value && required) {
      setInvalid(true);
      setError(errors.required);
    } else if (target.value && !isValid(date)) {
      setInvalid(true);
      setError(errors.invalid);
    } else {
      setError(undefined);
      setInvalid(false);
    }
  };

  return (
    <div className={classMap.join(' ')}>
      {label && <Form.Label>{label}</Form.Label>}
      <DatePicker
        dateFormat='dd/MM/yyyy'
        locale='en-GB'
        selected={startDate}
        onChange={onDateInput}
        onBlur={handleOnBlur}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        customInput={<CustomDateInput isInvalid={invalid} error={error} />}
      />
    </div>
  );
};

export default DateField;

import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '../Buttons';
import { FullUser } from '../../@types';
import { useAdminApi } from '../../hooks';

type ConfirmUserDeleteProps = {
  show: boolean;
  handleClose: () => void;
  handleConfirm: (userToDelete: FullUser) => void;
  userToDelete: FullUser;
};

export const ConfirmUserDelete: FC<ConfirmUserDeleteProps> = ({ show, handleClose, handleConfirm, userToDelete }) => {
  const { deleteUser } = useAdminApi();
  const confirmAndDelete = async () => {
    await deleteUser(userToDelete.userAccount.uid);
    handleConfirm(userToDelete);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the user account for <em>{userToDelete.user.email}</em>?
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton label='Cancel' handleClick={handleClose}></PrimaryButton>
        <PrimaryButton label='Yes' handleClick={confirmAndDelete}></PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

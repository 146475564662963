import { getReferenceItemFeature, LoadStatus } from '@bcx-tech/frontend-core';
import { Coach } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const entityAdapterProps = {
  selectId: (coach: Coach) => coach.id,
};

const referenceItemFeature = getReferenceItemFeature<Coach>('coaches', entityAdapterProps);

const { slice, entityAdapter } = referenceItemFeature;

export const {
  fetchItems: fetchCoaches,
  fetchAllItems,
  fetchItem: fetchCoach,
  createNewItem: createNewCoach,
  updateItem,
  deleteItem,
} = referenceItemFeature;

export const selectCoaches = (state: RootState, ids: string[]): Coach[] => {
  const coaches: Coach[] = [];
  Object.keys(state.coaches.entities).forEach((id) => {
    if (ids.includes(id)) {
      coaches.push(state.coaches.entities[id] as Coach);
    }
  });
  return coaches;
};

export const selectCoachLoadSuccess = (state: RootState) => state.coaches.status === LoadStatus.SUCCEEDED;

export const { selectById: selectCoach, selectAll: selectAllCoaches } = entityAdapter.getSelectors(
  (state: RootState) => state.coaches
);

export const { setItem } = slice.actions;

export default slice.reducer;

import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { Promoter } from '@bcx-tech/tbc-types';
import { fetchPromoter, selectPromoter, createNewPromoter, updatePromoter } from '../../features/promotersSlice';
import { TextField } from '@bcx-tech/weave';
import { PrimaryButton } from '../../components/Buttons';
import { useFormFields } from '../../hooks';

import { RootState } from '../../store';

type FormState = Partial<Promoter>;

type EditFormProps = {
  handleSubmit: (formState: FormState) => void;
  promoter?: Promoter;
};

const EditForm: FC<EditFormProps> = ({ handleSubmit, promoter }) => {
  const initialFormState: FormState | undefined = promoter
    ? {
        ...promoter,
      }
    : {};

  const { formState, createChangeHandler } = useFormFields<FormState>(initialFormState);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    try {
      if (!form.checkValidity()) {
        throw new Error('check you have entered all of the required fields');
      }
      await handleSubmit(formState);
    } catch (err) {
      setValidated(false);
      e.stopPropagation();
      const { message } = err as Error;
      setError({ isError: true, message: `There was an error saving the promoter: ${message}` });
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {error.isError && <Alert variant='danger'>{error.message}</Alert>}
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <TextField text={promoter?.name} label='Promoter name' required onChangeText={createChangeHandler('name')} />
        <div className='mb-3'>
          <PrimaryButton type='submit' label='Save' />
        </div>
      </Form>
    </>
  );
};

export const PromotersEdit: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { promoterId } = params;
  const promoter: Promoter | undefined = useSelector((state: RootState) => selectPromoter(state, promoterId || ''));

  useEffect(() => {
    if (!promoter && promoterId) {
      dispatch(fetchPromoter(promoterId));
    }
  }, [promoterId, dispatch]);

  const handleSubmit = async (formState: FormState) => {
    const { id, name, searchableName } = formState;

    const promoter = id
      ? ({
          id,
          name,
          searchableName,
        } as Promoter)
      : ({
          name,
          searchableName: name?.toLowerCase(),
        } as Omit<Promoter, 'id'>);

    if (!id) {
      await dispatch(createNewPromoter(promoter as Omit<Promoter, 'id'>));
    } else {
      await dispatch(updatePromoter(promoter as Promoter));
    }
    navigate('/promoters');
  };

  return (
    <>
      {(!promoterId || promoter) && (
        <>
          <h1>
            {promoter?.name ? (
              <>
                Edit <em>{promoter.name}</em>
              </>
            ) : (
              <>Add a promoter</>
            )}
          </h1>
          <EditForm handleSubmit={handleSubmit} promoter={promoter} />
        </>
      )}
    </>
  );
};

import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { Adjudicator } from '@bcx-tech/tbc-types';
import {
  fetchAdjudicator,
  selectAdjudicator,
  createNewAdjudicator,
  updateAdjudicator,
} from '../../features/adjudicatorsSlice';
import { TextField } from '@bcx-tech/weave';
import { PrimaryButton } from '../../components/Buttons';
import { useFormFields } from '../../hooks';

import { RootState } from '../../store';

type FormState = Partial<Adjudicator>;

type EditFormProps = {
  handleSubmit: (formState: FormState) => void;
  adjudicator?: Adjudicator;
};

const EditForm: FC<EditFormProps> = ({ handleSubmit, adjudicator }) => {
  const initialFormState: FormState | undefined = adjudicator
    ? {
        ...adjudicator,
      }
    : {};

  const { formState, createChangeHandler } = useFormFields<FormState>(initialFormState);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    try {
      if (!form.checkValidity()) {
        throw new Error('check you have entered all of the required fields');
      }
      await handleSubmit(formState);
    } catch (err) {
      setValidated(false);
      e.stopPropagation();
      const { message } = err as Error;
      setError({ isError: true, message: `There was an error saving the promoter: ${message}` });
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {error.isError && <Alert variant='danger'>{error.message}</Alert>}
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <TextField
          text={adjudicator?.name}
          label='Adjudicator name'
          required
          onChangeText={createChangeHandler('name')}
        />
        <div className='mb-3'>
          <PrimaryButton type='submit' label='Save' />
        </div>
      </Form>
    </>
  );
};

export const AdjudicatorsEdit: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { adjudicatorId } = params;
  const adjudicator: Adjudicator | undefined = useSelector((state: RootState) =>
    selectAdjudicator(state, adjudicatorId || '')
  );

  useEffect(() => {
    if (!adjudicator && adjudicatorId) {
      dispatch(fetchAdjudicator(adjudicatorId));
    }
  }, [adjudicatorId, dispatch]);

  const handleSubmit = async (formState: FormState) => {
    const { id, name, searchableName } = formState;

    const adjudicator = id
      ? ({
          id,
          name,
          searchableName,
        } as Adjudicator)
      : ({
          name,
          searchableName: name?.toLowerCase(),
        } as Omit<Adjudicator, 'id'>);

    if (!id) {
      dispatch(createNewAdjudicator(adjudicator as Omit<Adjudicator, 'id'>));
    } else {
      dispatch(updateAdjudicator(adjudicator as Adjudicator));
    }
    navigate('/adjudicators');
  };

  return (
    <>
      {(!adjudicatorId || adjudicator) && (
        <>
          <h1>
            {adjudicator?.name ? (
              <>
                Edit <em>{adjudicator.name}</em>
              </>
            ) : (
              <>Add an adjudicator</>
            )}
          </h1>
          <EditForm handleSubmit={handleSubmit} adjudicator={adjudicator} />
        </>
      )}
    </>
  );
};

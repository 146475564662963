import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAdjudicators, fetchAdjudicators } from '../../features/adjudicatorsSlice';
import { RowLabelListView } from '../../components/RowLabelListView';

import { RootState } from '../../store';

type AdjudicatorsViewProps = {
  adjudicatorIds: string[];
};
export const AdjudicatorsView: FC<AdjudicatorsViewProps> = ({ adjudicatorIds }) => {
  const dispatch = useDispatch();
  const adjudicatorCollection = useSelector((state: RootState) => selectAdjudicators(state, adjudicatorIds || []));

  useEffect(() => {
    if (!adjudicatorCollection.length) {
      dispatch(fetchAdjudicators(adjudicatorIds));
    }
  }, [adjudicatorIds]);

  return (
    <>
      {!!adjudicatorCollection?.length && (
        <RowLabelListView label='Adjudicators' itemArray={adjudicatorCollection.map(({ name }) => name)} />
      )}
    </>
  );
};

import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { AdjudicatorsView } from './AdjudicatorsView';
import { DanceLeaguesView } from './DanceLeaguesView';
import { BooleanView } from '../../components/BooleanView';
import { RowLabelListView } from '../../components/RowLabelListView';

import { categoriesList, showDanceOptions } from '@bcx-tech/frontend-core';

type CompetitionViewProps = {
  event: DanceEvent;
};
const CompetitionView: FC<CompetitionViewProps> = ({ event }) => {
  const categories = event.categories?.map((category) => {
    const currentCategory = categoriesList.filter(({ key }) => key === category.category).reduce((curr) => curr);
    return {
      key: currentCategory.key,
      name: currentCategory.value,
      danceStyles: currentCategory.danceStyles
        .filter(({ value }) => category.danceStyles && category.danceStyles.includes(value))
        .map(({ label }) => label),
      ageLevels: currentCategory.ageCategories
        .filter(({ value }) => category.ageLevels && category.ageLevels.includes(value))
        .map(({ label }) => label),
      showDanceOptions: showDanceOptions
        .filter(({ value }) => category.showDanceOptions && category.showDanceOptions.includes(value))
        .map(({ value }) => value),
      cashPrizes: category.cashPrizes || false,
      showDanceEvent: category.showDanceEvent || false,
      formationEvent: category.formationEvent || false,
      hasSoloRounds: category.hasSoloRounds || false,
    };
  });

  return (
    <>
      <h4>Information about this competition</h4>
      {categories &&
        categories.map((category, idx: number) => (
          <Card key={idx}>
            <Card.Title>{category.name}</Card.Title>
            <RowLabelListView label='Dance styles' itemArray={category.danceStyles} />
            <RowLabelListView label='Age levels' itemArray={category.ageLevels} />

            {category.showDanceEvent && (
              <RowLabelListView label='Show dance options' itemArray={category.showDanceOptions} />
            )}
            {['AMATEUR', 'PROFESSIONAL', 'SAMESEX'].includes(category.key) && (
              <BooleanView label='Formation Event' value={category.formationEvent}></BooleanView>
            )}
            <BooleanView label='Cash Prizes' value={category.cashPrizes}></BooleanView>
            <BooleanView label='Event has solo rounds' value={category.hasSoloRounds}></BooleanView>
          </Card>
        ))}
      {!!event.danceLeagues?.length && <DanceLeaguesView danceLeagueIds={event.danceLeagues}></DanceLeaguesView>}
      {!!event.adjudicators?.length && <AdjudicatorsView adjudicatorIds={event.adjudicators}></AdjudicatorsView>}
    </>
  );
};

export default CompetitionView;

import React, { FC, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { useAnalytics } from '@bcx-tech/frontend-core';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { EventsListAll } from './EventsListAll';
import { EventsListByPromoter } from './EventsListByPromoter';
import { EventsListByAdjudicator } from './EventsListByAdjudicator';
import { EventsListByCoach } from './EventsListByCoach';

import { ConfirmEventDelete } from '../../components/ConfirmEventDelete';

enum TabKey {
  All = 'all',
  ByPromoter = 'byPromoter',
  ByAdjudicator = 'byAdjudicator',
  ByCoach = 'byCoach',
}

export const EventsList: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultActiveTab = searchParams.get('tab') || TabKey.All;
  const [key, setKey] = useState<string>(defaultActiveTab);
  const [show, setShow] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<DanceEvent>();
  const { logAnalyticsEvent } = useAnalytics();

  logAnalyticsEvent('page_view', {
    page_title: 'EventsList',
  });

  useEffect(() => {
    if (searchParams.get('tab')) {
      if (key === TabKey.ByPromoter && searchParams.get('promoterId')) {
        const promoterId = searchParams.get('promoterId') as string;
        setSearchParams({ tab: key, promoterId });
      } else {
        setSearchParams({ tab: key });
      }
    }
  }, [key]);

  const handleClose = () => {
    setEventToDelete(undefined);
    setShow(false);
  };
  const handleConfirmDelete = (event: DanceEvent) => {
    setEventToDelete(event);
    setShow(true);
  };

  return (
    <>
      <Tabs
        id='events-tab'
        activeKey={key}
        onSelect={(k) => setKey(k || defaultActiveTab)}
        mountOnEnter={true}
        unmountOnExit={true}
        className='mb-3'
      >
        <Tab eventKey={TabKey.All} title='All Events'>
          <EventsListAll confirmDeleteHandler={handleConfirmDelete} />
        </Tab>
        <Tab eventKey={TabKey.ByPromoter} title='Events by Promoter'>
          <EventsListByPromoter confirmDeleteHandler={handleConfirmDelete} />
        </Tab>
        <Tab eventKey={TabKey.ByAdjudicator} title='Events by Adjudicator'>
          <EventsListByAdjudicator confirmDeleteHandler={handleConfirmDelete} />
        </Tab>
        <Tab eventKey={TabKey.ByCoach} title='Events by Coach'>
          <EventsListByCoach confirmDeleteHandler={handleConfirmDelete} />
        </Tab>
      </Tabs>
      {eventToDelete && (
        <ConfirmEventDelete
          eventToDelete={eventToDelete}
          handleConfirm={handleClose}
          handleClose={handleClose}
          show={show}
        />
      )}
    </>
  );
};

import React, { FC } from 'react';
import { getDateParts } from '@bcx-tech/frontend-core';

type DateViewProps = {
  startDate: number;
  endDate?: number;
};

export const DateView: FC<DateViewProps> = ({ startDate, endDate }) => {
  const { month, day, year } = getDateParts(startDate);
  let endDateParts;
  if (endDate) {
    endDateParts = getDateParts(endDate);
  }

  return (
    <div className='d-flex align-items-center'>
      <span className='font-weight-light text-uppercase'>
        {day} {month} {year}
        {endDateParts && (
          <>
            &nbsp;- {endDateParts.day} {endDateParts.month} {endDateParts.year}
          </>
        )}
      </span>
    </div>
  );
};

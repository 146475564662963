import React, { FC, useState, FormEvent } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { UserCard } from '../../components/UserCard';
import { ConfirmUserDelete } from '../../components/ConfirmUserDelete';
import { TextField } from '@bcx-tech/weave';
import { useAdminApi } from '../../hooks';
import { FullUser } from '../../@types';

export const ManageUsers: FC = () => {
  const [emailAddress, setEmailAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<FullUser>();
  const [alert, setAlert] = useState<{ isAlert: boolean; isError: boolean; message?: string }>({
    isAlert: false,
    isError: false,
  });
  const [show, setShow] = useState(false);
  const [userToDelete, setUserToDelete] = useState<FullUser>();
  const { getUserByEmail } = useAdminApi();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (emailAddress) {
      setLoading(true);
      try {
        const user = await getUserByEmail(emailAddress);
        setUserData(user);
        setAlert({ isAlert: false, isError: false });
      } catch (err) {
        const { message } = err as Error;
        setAlert({ isAlert: true, isError: true, message });
      }
      setLoading(false);
    }
  };

  const onReset = async (e: FormEvent) => {
    e.preventDefault();
    setEmailAddress(undefined);
    setUserData(undefined);
  };

  const handleConfirmDelete = (user: FullUser) => {
    setUserToDelete(user);
    setShow(true);
  };
  const handleConfirm = (deletedUser: FullUser) => {
    setUserToDelete(undefined);
    setEmailAddress(undefined);
    setUserData(undefined);
    setShow(false);
    setAlert({ isAlert: true, isError: false, message: `User ${deletedUser.user.email} has been deleted` });
  };
  const handleClose = () => {
    setUserToDelete(undefined);
    setShow(false);
  };

  return (
    <>
      <h1>Manage Users</h1>
      {alert.isAlert && <Alert variant={alert.isError ? 'danger' : 'info'}>{alert.message}</Alert>}
      <Form onSubmit={onSubmit} onReset={onReset}>
        <TextField onChangeText={setEmailAddress} label='Search for a user by email address' />
        <PrimaryButton loading={loading} type='submit' label='Load user' />
        <SecondaryButton type='reset' label='Clear' />
      </Form>
      {userData && <UserCard user={userData} onDeleteClickHandler={handleConfirmDelete} />}
      {userToDelete && userData && (
        <ConfirmUserDelete
          userToDelete={userData}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          show={show}
        />
      )}
    </>
  );
};

import { LoadStatus, eventsFeature } from '@bcx-tech/frontend-core';
import { DanceEvent, Promoter, Adjudicator, Coach } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const { slice, eventsAdapter } = eventsFeature;

export const { fetchEvents, fetchEvent, searchEvents, createNewEvent, updateEvent, deleteEvent } = eventsFeature;

export const selectEventsStatus = (state: RootState) => state.events.status;
export const selectEventLoadSuccess = (state: RootState) => state.events.status === LoadStatus.SUCCEEDED;
export const selectLastItem = (state: RootState) => state.events.lastItem;

export const searchByPromoterId = (promoter: Promoter) => {
  return searchEvents({
    criteria: [
      {
        field: 'promoters',
        op: 'array-contains',
        value: promoter.id,
      },
    ],
  });
};

export const searchByAdjudicatorId = (adjudicator: Adjudicator) => {
  return searchEvents({
    criteria: [
      {
        field: 'adjudicators',
        op: 'array-contains',
        value: adjudicator.id,
      },
    ],
  });
};

export const searchByCoachId = (coach: Coach) => {
  return searchEvents({
    criteria: [
      {
        field: 'coaches',
        op: 'array-contains',
        value: coach.id,
      },
    ],
  });
};

export const { selectById: selectEvent, selectAll: selectEvents } = eventsAdapter.getSelectors(
  (state: RootState) => state.events
);

export const selectFilteredEvents = (state: RootState) => {
  const events = selectEvents(state);
  return events.filter((event: DanceEvent) => state.events.filteredIds && state.events.filteredIds.includes(event.id));
};

export const { searchResultsCleared } = slice.actions;

export default slice.reducer;

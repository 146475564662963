import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

type NewPasswordFieldProps = {
  onChangeText: (value: string) => void;
  showLabels?: boolean;
};

export const NewPasswordField: FC<NewPasswordFieldProps> = ({ onChangeText, showLabels = true }) => {
  const [password, setPassword] = useState<string>();
  const [passwordConfirm, setPasswordConfirm] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    if (password && passwordConfirm && isValid && touched) {
      onChangeText(password);
    }
  }, [isValid]);

  const onPasswordInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setPassword(target.value);
    setIsValid(target.value === passwordConfirm);
  };

  const checkPasswordsDiffer = () => touched && password !== passwordConfirm;

  const onPasswordConfirmInput = (event: ChangeEvent) => {
    setTouched(true);
    const target = event.target as HTMLInputElement;
    setPasswordConfirm(target.value);
    setIsValid(target.value === password);
  };

  return (
    <>
      <Form.Group className='mb-3'>
        {showLabels && <Form.Label htmlFor='new-password'>Enter a new Password</Form.Label>}
        <Form.Control
          isInvalid={checkPasswordsDiffer()}
          id='new-password'
          type='password'
          placeholder='New Password'
          aria-label='New Password'
          onChange={onPasswordInput}
          autoComplete='new-password'
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        {showLabels && <Form.Label htmlFor='confirm-password'>Re-enter your new Password</Form.Label>}
        <Form.Control
          isInvalid={checkPasswordsDiffer()}
          id='confirm-password'
          type='password'
          placeholder='Confirm new password'
          aria-label='Confirm new password'
          onChange={onPasswordConfirmInput}
          autoComplete='new-password'
        />
        <Form.Control.Feedback type='invalid'>Passwords do not match</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

const {
  REACT_APP_NR_LICENSE_KEY: licenseKey,
  REACT_APP_NR_APPLICATION_ID: applicationID,
  REACT_APP_NR_ACCOUNT_ID: accountID,
} = process.env;

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.eu01.nr-data.net'] },
  }, // NREUM.init
  info: {
    beacon: 'bam.eu01.nr-data.net',
    errorBeacon: 'bam.eu01.nr-data.net',
    licenseKey,
    applicationID,
    sa: 1,
  }, // NREUM.info
  loader_config: {
    accountID,
    trustKey: accountID,
    agentID: applicationID,
    licenseKey,
    applicationID,
  }, // NREUM.loader_config
};

export default new BrowserAgent(options);

import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import styles from './Buttons.module.css';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  loadingLabel?: string;
  label: string;
};

export const PrimaryButton: FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
}) => {
  return (
    <Button
      className={styles['btn-primary']}
      variant='primary'
      disabled={disabled || !!loading}
      type={type}
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {loading ? loadingLabel : label}
    </Button>
  );
};

export const SecondaryButton: FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
}) => {
  return (
    <Button
      className={styles['btn-secondary']}
      variant='secondary'
      disabled={disabled || !!loading}
      type={type}
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {loading ? loadingLabel : label}
    </Button>
  );
};

import React, { FC } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatTimestamp } from '@bcx-tech/frontend-core';

import { DanceEvent } from '@bcx-tech/tbc-types';

const eventTypeOptions: { [key: string]: string } = {
  COMPETITION: 'Competition',
  TRAININGCAMP: 'Masterclass or Training Camp',
};

const formatEventType = (eventType: string) => eventTypeOptions[eventType];

type EventsListTable = {
  events: DanceEvent[];
  onDeleteClickHandler: (event: DanceEvent) => void;
};

export const EventsListTable: FC<EventsListTable> = ({ events, onDeleteClickHandler }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Type</th>
          <th>Date</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event: DanceEvent, idx: number) => (
          <tr key={idx}>
            <td>{event.title}</td>
            <td>{formatEventType(event.eventType)}</td>
            <td>{formatTimestamp(event.startDate)}</td>
            <td>
              <div className='btn-group'>
                <Link className='btn btn-sm btn-outline-info' to={`/events/${event.id}`}>
                  View
                </Link>
                <Link className='btn btn-sm btn-outline-info' to={`/events/${event.id}/edit`}>
                  Edit
                </Link>
                <a className='btn btn-sm btn-outline-info' onClick={() => onDeleteClickHandler(event)}>
                  Delete
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

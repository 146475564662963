import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectDanceLeagues, fetchDanceLeagues } from '../../features/danceLeaguesSlice';
import { RowLabelListView } from '../../components/RowLabelListView';

import { RootState } from '../../store';

type DanceLeaguesViewProps = {
  danceLeagueIds: string[];
};
export const DanceLeaguesView: FC<DanceLeaguesViewProps> = ({ danceLeagueIds }) => {
  const dispatch = useDispatch();
  const danceLeagueCollection = useSelector((state: RootState) => selectDanceLeagues(state, danceLeagueIds || []));

  useEffect(() => {
    if (!danceLeagueCollection.length) {
      dispatch(fetchDanceLeagues(danceLeagueIds));
    }
  }, [danceLeagueIds]);

  return (
    <>
      {!!danceLeagueCollection?.length && (
        <RowLabelListView label='Dance Leagues' itemArray={danceLeagueCollection.map(({ name }) => name)} />
      )}
    </>
  );
};

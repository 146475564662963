import { getReferenceItemFeature, LoadStatus } from '@bcx-tech/frontend-core';
import { DanceLeague } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const entityAdapterProps = {
  selectId: (adjudicator: DanceLeague) => adjudicator.id,
  sortComparer: (a: DanceLeague, b: DanceLeague) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  },
};

const referenceItemFeature = getReferenceItemFeature<DanceLeague>('danceLeagues', entityAdapterProps);

const { slice, entityAdapter } = referenceItemFeature;

export const {
  fetchItems: fetchDanceLeagues,
  fetchAllItems,
  fetchItem: fetchDanceLeague,
  createNewItem: createNewDanceLeague,
  updateItem: updateDanceLeague,
  deleteItem,
} = referenceItemFeature;

export const selectDanceLeagues = (state: RootState, ids: string[]): DanceLeague[] => {
  const danceLeagues: DanceLeague[] = [];
  Object.keys(state.danceLeagues.entities).forEach((id) => {
    if (ids.includes(id)) {
      danceLeagues.push(state.danceLeagues.entities[id] as DanceLeague);
    }
  });
  return danceLeagues;
};

export const selectDanceLeagueLoadSuccess = (state: RootState) => state.danceLeagues.status === LoadStatus.SUCCEEDED;

export const { selectById: selectDanceLeague, selectAll: selectAllDanceLeagues } = entityAdapter.getSelectors(
  (state: RootState) => state.danceLeagues
);

export default slice.reducer;

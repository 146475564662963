import React, { FC, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useUpdatePassword } from '@bcx-tech/frontend-core';
import { Link } from 'react-router-dom';

import { NewPasswordField } from '../../components/NewPasswordField';
import { AuthAlert } from '../../components/AuthAlert';
import { PrimaryButton } from '../../components/Buttons';

export const ChangePassword: FC = () => {
  const [updatePassword, loading, error] = useUpdatePassword();
  const [newPassword, setNewPassword] = useState<string>();
  const [success, setSuccess] = useState<boolean>();

  const handleClick = async () => {
    if (newPassword) {
      await updatePassword(newPassword);
      if (!loading && !error) {
        setSuccess(true);
        setNewPassword(undefined);
      }
    }
  };

  return (
    <>
      <h1>Change password</h1>
      {error && <AuthAlert error={error} />}
      {success && (
        <Alert variant='success'>
          <h4>Password updated successfully</h4>
          Your password has been changed. <Link to={'/settings'}>Go back to settings</Link>.
        </Alert>
      )}
      <Form>
        <NewPasswordField onChangeText={(text: string) => setNewPassword(text)} />
        <PrimaryButton
          loading={loading}
          loadingLabel='Updating password...'
          handleClick={handleClick}
          disabled={!newPassword}
          label='Change password'
        />
      </Form>
    </>
  );
};

import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSignOutUser } from '@bcx-tech/frontend-core';
import { PrimaryButton } from '../Buttons';
import styles from './LoggedInUser.module.css';
import { User } from 'firebase/auth';

export const LoggedInUser: FC<{ user: User }> = ({ user }) => {
  const { signOutUser } = useSignOutUser();
  return (
    <Container className={styles.container}>
      <Row>
        <Col md={{ span: 4, offset: 8 }}>
          <Link to={'settings'}>Logged in as {user.email}</Link>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 8 }}>
          <PrimaryButton handleClick={async () => signOutUser()} label='Sign out' />
        </Col>
      </Row>
    </Container>
  );
};

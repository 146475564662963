import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Settings: FC = () => {
  return (
    <>
      <h1>Welcome back</h1>
      <div className='d-flex justify-content-between align-items-stretch'>
        <Card className='m-3 w-50 flex-fill'>
          <Card.Title className='mx-3 mt-3'>
            <Link to={'change-password'}>Change password</Link>
          </Card.Title>
          <Card.Body>Change your password</Card.Body>
        </Card>
      </div>
    </>
  );
};

import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { categoriesList, eventTypeChoices, useStorage } from '@bcx-tech/frontend-core';
import { DanceEvent, Adjudicator, Coach, Promoter, FileReference, DanceLeague } from '@bcx-tech/tbc-types';
import { fetchEvent, selectEvent, createNewEvent, updateEvent } from '../../features/eventsSlice';
import {
  AddressField,
  EmailField,
  LongTextField,
  SingleChoiceField,
  TelephoneField,
  TextField,
  UploadField,
  WebsiteField,
  FileReference as UploadFileReference,
} from '@bcx-tech/weave';
import { PrimaryButton } from '../../components/Buttons';
import DateField from '../../components/DateField';
import TimeField from '../../components/TimeField';
import { useFormFields, useLinkedEntity } from '../../hooks';

import { EventCategoriesEdit } from './EventCategoriesEdit';
import { AdjudicatorsEdit } from './AdjudicatorsEdit';
import { CoachesEdit } from './CoachesEdit';
import { PromotersEdit } from './PromotersEdit';
import { DanceLeaguesEdit } from './DanceLeaguesEdit';

import { RootState } from '../../store';

const binaryChoices = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

type FormState = Partial<DanceEvent> & {
  adjudicatorReferences?: Adjudicator[];
  coachReferences?: Coach[];
  promoterReferences?: Promoter[];
  danceLeagueReferences?: DanceLeague[];
};

type EditFormProps = {
  handleSubmit: (formState: FormState) => void;
  event?: DanceEvent;
};

const EditForm: FC<EditFormProps> = ({ handleSubmit, event }) => {
  const initialFormState: FormState | undefined = event
    ? {
        ...event,
      }
    : {};

  const { formState, createChangeHandler, setFormValue } = useFormFields<FormState>(initialFormState);
  const { uploadFile, deleteFile } = useStorage();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    try {
      if (!form.checkValidity()) {
        throw new Error('check you have entered all of the required fields');
      }
      await handleSubmit(formState);
    } catch (err) {
      setValidated(false);
      e.stopPropagation();
      const { message } = err as Error;
      setError({ isError: true, message: `There was an error saving the event: ${message}` });
      window.scrollTo(0, 0);
    }
  };

  const handleLogoImage = (i: UploadFileReference | UploadFileReference[]) => {
    const image = i as UploadFileReference;
    setFormValue('image', {
      id: uuidv4(),
      ...image,
    } as FileReference);
  };

  const handleAttachments = (f: UploadFileReference | UploadFileReference[]) => {
    const files = f as UploadFileReference[];
    const attachments = files.map((file) => ({
      id: uuidv4(),
      ...file,
    }));
    setFormValue('attachments', attachments);
  };

  return (
    <>
      {error.isError && <Alert variant='danger'>{error.message}</Alert>}
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <SingleChoiceField
          choice={event?.eventType}
          choices={eventTypeChoices}
          type='button'
          label='Type of event:'
          onChoose={createChangeHandler('eventType')}
        />

        {!!formState.eventType && (
          <>
            <TextField
              text={event?.title}
              label={formState.eventType === 'COMPETITION' ? 'Competition name:' : 'Masterclass/Training Camp title:'}
              required
              onChangeText={createChangeHandler('title')}
            />
            <UploadField
              label='Image or logo:'
              allowMultiple={false}
              previewImages={true}
              uploadFile={async (file) => uploadFile('eventdata', file)}
              deleteFile={async (fileReference) => deleteFile(fileReference as FileReference)}
              onChange={handleLogoImage}
              existingFiles={event?.image ? [event.image] : []}
              allowedExtensions={IMAGE_EXTENSIONS}
            />
            <LongTextField
              label='Event description:'
              text={event?.description}
              onChangeText={createChangeHandler('description')}
            />
            <Row>
              <Col sm={6}>
                <DateField
                  label='Start date:'
                  date={event?.startDate}
                  required
                  onChange={createChangeHandler('startDate')}
                />
              </Col>
              <Col sm={6}>
                <DateField label='End date:' date={event?.endDate} required onChange={createChangeHandler('endDate')} />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <TimeField
                  label={formState.eventType === 'COMPETITION' ? 'Doors open:' : 'Start time:'}
                  time={event?.time}
                  onChange={createChangeHandler('time')}
                />
              </Col>
              <Col sm={6}>
                <TimeField label='Finishes at:' time={event?.endTime} onChange={createChangeHandler('endTime')} />
              </Col>
            </Row>
            {formState.eventType === 'TRAININGCAMP' && (
              <LongTextField
                label='Tickets and pricing details:'
                text={event?.pricingDetails}
                onChangeText={createChangeHandler('pricingDetails')}
              />
            )}
            <AddressField address={event?.address} onChange={createChangeHandler('address')} />
            <WebsiteField label='Website:' url={event?.website} onChangeText={createChangeHandler('website')} />
            <PromotersEdit onChange={createChangeHandler('promoterReferences')} promoterIds={event?.promoters} />
            <UploadField
              label='Event Brochures:'
              uploadFile={async (file) => uploadFile('eventdata', file)}
              deleteFile={async (fileReference) => deleteFile(fileReference as FileReference)}
              onChange={handleAttachments}
              existingFiles={event?.attachments}
            />
            <EmailField
              label='Email address:'
              showAddon={false}
              emailAddress={event?.contactEmail}
              onChangeText={createChangeHandler('contactEmail')}
            />
            <TelephoneField
              label='Phone:'
              phoneNumber={event?.contactPhone}
              onChangeText={createChangeHandler('contactPhone')}
            />

            {formState.eventType === 'COMPETITION' && (
              <>
                <EventCategoriesEdit
                  categories={categoriesList}
                  currentValues={event?.categories}
                  onChange={createChangeHandler('categories')}
                />
                <SingleChoiceField
                  type='radio'
                  label='Is the competition part of a dance league or championship?'
                  choice={event?.danceLeagueEvent ? 'true' : 'false'}
                  choices={binaryChoices}
                  onChoose={createChangeHandler('danceLeagueEvent')}
                />
                {!!formState.danceLeagueEvent && (
                  <div className='ms-4'>
                    <DanceLeaguesEdit
                      onChange={createChangeHandler('danceLeagueReferences')}
                      danceLeagueIds={event?.danceLeagues}
                    />
                  </div>
                )}
                <AdjudicatorsEdit
                  onChange={createChangeHandler('adjudicatorReferences')}
                  adjudicatorIds={event?.adjudicators}
                />
              </>
            )}
            {formState.eventType === 'TRAININGCAMP' && (
              <CoachesEdit onChange={createChangeHandler('coachReferences')} coachIds={event?.coaches} />
            )}
          </>
        )}
        {!!formState.eventType && (
          <div className='mb-3'>
            <PrimaryButton type='submit' label='Save' />
          </div>
        )}
      </Form>
    </>
  );
};

export const EventsEdit: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storeEntities: storeAdjudicators } = useLinkedEntity<Adjudicator>('judges-coaches');
  const { storeEntities: storeCoaches } = useLinkedEntity<Coach>('judges-coaches');
  const { storeEntities: storePromoters } = useLinkedEntity<Promoter>('promoters');
  const { storeEntities: storeDanceLeagues } = useLinkedEntity<DanceLeague>('dance-leagues');
  const params = useParams();
  const { eventId } = params;
  const event: DanceEvent | undefined = useSelector((state: RootState) => selectEvent(state, eventId || ''));

  useEffect(() => {
    if (!event && eventId) {
      dispatch(fetchEvent(eventId));
    }
  }, [eventId, dispatch]);

  const handleSubmit = async (formState: FormState) => {
    const { id, adjudicatorReferences, coachReferences, promoterReferences, danceLeagueReferences, ...eventData } =
      formState;

    delete eventData.adjudicators;
    delete eventData.coaches;
    delete eventData.promoters;
    delete eventData.danceLeagues;

    const event = id
      ? ({
          id,
          ...eventData,
        } as DanceEvent)
      : ({
          ...eventData,
        } as Omit<DanceEvent, 'id'>);

    if (adjudicatorReferences && adjudicatorReferences.length) {
      event.adjudicators = await storeAdjudicators(adjudicatorReferences);
    }
    if (coachReferences && coachReferences.length) {
      event.coaches = await storeCoaches(coachReferences);
    }
    if (promoterReferences && promoterReferences.length) {
      event.promoters = await storePromoters(promoterReferences);
    }
    if (danceLeagueReferences && danceLeagueReferences.length) {
      event.danceLeagues = await storeDanceLeagues(danceLeagueReferences);
    }

    if (!id) {
      dispatch(createNewEvent(event as Omit<DanceEvent, 'id'>));
    } else {
      dispatch(updateEvent(event as DanceEvent));
    }
    navigate('/events');
  };

  return (
    <>
      {(!eventId || event) && (
        <>
          <h1>
            {event?.title ? (
              <>
                Edit <em>{event.title}</em>
              </>
            ) : (
              <>Add an event</>
            )}
          </h1>
          <EditForm handleSubmit={handleSubmit} event={event} />
        </>
      )}
    </>
  );
};

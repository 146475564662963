import { initFirebaseApp } from '@bcx-tech/frontend-core';

const {
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_STORAGE_BUCKET_URL: storageBucket,
  REACT_APP_FIREBASE_APP_ID: appId,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_MEASUREMENT_ID: measurementId,
  REACT_APP_FIREBASE_RC_FETCH_INTERVAL_MS,
  REACT_APP_FIREBASE_RC_FETCH_TIMEOUT_MS,
  REACT_APP_FIREBASE_FUNCTIONS_REGION: functionRegion,
  REACT_APP_DOMAIN: domain,
  REACT_APP_ENVIRONMENT,
} = process.env;

export const app = initFirebaseApp({
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
});

const minimumFetchIntervalMillis = Number(REACT_APP_FIREBASE_RC_FETCH_INTERVAL_MS || 43200000);
const fetchTimeoutMillis = Number(REACT_APP_FIREBASE_RC_FETCH_TIMEOUT_MS || 60000);

export const config = {
  useEmulators: REACT_APP_ENVIRONMENT === 'local',
  rewriteMediaUrls: REACT_APP_ENVIRONMENT !== 'local',
  localIpAddr: '127.0.0.1',
  functionRegion,
  minimumFetchIntervalMillis,
  fetchTimeoutMillis,
  firebaseStorageBucket: 'bcx-tech-dev.appspot.com',
  target: 'https://ik.imagekit.io/k2i0yfalw2',
  domain: String(domain),
};

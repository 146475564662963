import axios from 'axios';
import { useContext } from 'react';
import { FirebaseContext } from '@bcx-tech/frontend-core';
import { DancerProfile, User } from '@bcx-tech/tbc-types';
import { FullUser, Roles } from '../@types';

const { REACT_APP_ADMIN_API: apiUrl } = process.env;

export const useAdminApi = () => {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('Firebase context not found');
  const { auth } = context;
  const baseUrl = String(apiUrl);

  const getHttpClient = async () => {
    const idToken = await auth.currentUser?.getIdToken();
    const httpClient = axios.create({
      baseURL: baseUrl,
      timeout: 5000,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return httpClient;
  };

  const getUserByEmail = async (email: string): Promise<FullUser> => {
    const client = await getHttpClient();
    const { data } = await client.post(`${baseUrl}/user/search`, { email });
    const roles: string[] = (data.userAccount.customClaims?.roles as unknown as string[]) || [];
    return { ...data, isAdmin: !!data.userAccount.customClaims?.admin, isPromoter: roles.includes(Roles.Promoter) };
  };

  const getUser = async (uid: string): Promise<FullUser> => {
    const client = await getHttpClient();
    const { data } = await client.get(`${baseUrl}/user/${uid}`);
    const roles: string[] = (data.userAccount.customClaims?.roles as unknown as string[]) || [];
    return { ...data, isAdmin: !!data.userAccount.customClaims?.admin, isPromoter: roles.includes(Roles.Promoter) };
  };

  const addUser = async (body: { user: User; password: string; dancerProfile?: DancerProfile }): Promise<void> => {
    const client = await getHttpClient();
    await client.post(`${baseUrl}/user`, { body });
  };

  const editUser = async (
    uid: string,
    body: {
      user?: Omit<User, 'id'>;
      dancerProfile?: DancerProfile;
    }
  ): Promise<void> => {
    const client = await getHttpClient();
    await client.put(`${baseUrl}/user/${uid}`, { body });
  };

  const makeAdmin = async (uid: string): Promise<void> => {
    const client = await getHttpClient();
    await client.put(`${baseUrl}/user/${uid}/roles`, { setAdmin: true });
  };

  const addPromoterRole = async (uid: string): Promise<void> => {
    const client = await getHttpClient();
    await client.put(`${baseUrl}/user/${uid}/roles`, { rolesToAdd: [Roles.Promoter] });
  };

  const removePromoterRole = async (uid: string): Promise<void> => {
    const client = await getHttpClient();
    await client.put(`${baseUrl}/user/${uid}/roles`, { rolesToRemove: [Roles.Promoter] });
  };

  const deleteUser = async (uid: string): Promise<void> => {
    const client = await getHttpClient();
    await client.delete(`${baseUrl}/user/${uid}`);
  };

  return { getUserByEmail, getUser, addUser, editUser, makeAdmin, addPromoterRole, removePromoterRole, deleteUser };
};
